import { useEffect, useState, useRef } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { json, useNavigate, useParams } from "react-router-dom";
import { store } from '../../../redux/store';
import { removeUser } from '../../../redux/userSlice';
import useFetch from '../../../useFetch';
import Input from "./../Forms/Input";
import Select from "./../Forms/Select";
import Dialogpopup from '../../Dialogpopup';
import Apiaddres from '../Apiaddres';
import md5 from "md5";
import Footer from '../../Footer';

import { useSelector } from 'react-redux';


const Edit = () => {
    document.title = "Edit Customer | Smart Trucking Services";
    

    const { id } = useParams();
 
    let navigate = useNavigate();
    const[btnenable,setbtnenable] = useState("none")
    const[btndisable,setbtndisable] = useState("none")
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();

    const [legalName, setLegalName] = useState('');
    const [tradeName, setTradeName] = useState('');
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [streetName, setStreetName] = useState('');
    const [suiteApt, setSuiteApt] = useState('');
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [telephone, setTelephone] = useState('');
    const [cell, setCell] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [cvor, setCvor] = useState('');
    const [cvorDate, setCvorDate] = useState('');
    const [cvorIssueDate, setCvorIssueDate] = useState('');
    const [cvorExpiryDate, setCvorExpiryDate] = useState('');
    const [usDot, setUsDot] = useState('NA');
    const [usDotPin, setUsDotPin] = useState('');
    const [usDotDate, setUsDotDate] = useState('');
    const [iftaAcc, setIftaAcc] = useState('');
    const [orFile, setOrFile] = useState('NA');
    const [ny, setNy] = useState('NA');
    const [kyu, setKyu] = useState('NA');
    const [ct, setCt] = useState('NA');
    const [ucr, setUcr] = useState('');
    const [nmwdt, setNmwdt] = useState('NA');
    const [qcnir, setQcnir] = useState('');
    const [fedId, setFedId] = useState('');
    const [gst, setGst] = useState('');
    const [mcNo, setMcNo] = useState('');
    const [irpAcc1, setIrpAcc1] = useState('');
    const [irpAcc2, setIrpAcc2] = useState('');
    const [irpAcc3, setIrpAcc3] = useState('');
    const [expireDate1, setExpireDate1] = useState('');
    const [expireDate2, setExpireDate2] = useState('');
    const [expireDate3, setExpireDate3] = useState('');
    const [weightGroup1A, setWeightGroup1A] = useState('');
    const [weightGroup1B, setWeightGroup1B] = useState('');
    const [weightGroup1C, setWeightGroup1C] = useState('');
    const [weightGroup2A, setWeightGroup2A] = useState('');
    const [weightGroup2B, setWeightGroup2B] = useState('');
    const [weightGroup2C, setWeightGroup2C] = useState('');
    const [weightGroup3A, setWeightGroup3A] = useState('');
    const [weightGroup3B, setWeightGroup3B] = useState('');
    const [weightGroup3C, setWeightGroup3C] = useState('');
    const [scac1, setScac1] = useState('');
    const [scac2, setScac2] = useState('');
    const [scac3, setScac3] = useState('');
    const [carrierCode1, setCarrierCode1] = useState('');
    const [carrierCode2, setCarrierCode2] = useState('');
    const [carrierCode3, setCarrierCode3] = useState('');
    const [carrierCodeDate, setCarrierCodeDate] = useState('');
    const [scacDate, setScacDate] = useState('');
    const [scacPassword, setScacPassword] = useState('');
    const [isIfta, setIsIfta] = useState(false);
    const [isSafety, setIsSafety] = useState(false);
    const [isSafety2,setIsSafetye2] = useState(false);
    const [isActivated, setIsActivated] = useState(true);
    const [quarter, setQuarter] = useState('');
    const[notificationemail,setnotificationemail] = useState('')
const[showdialog,setdialog] = useState('none')
const [enabledialog,setenabledialog] = useState('none')
const [savedialogalter,setsavedialogalert] = useState('none')
const [uname, setName] = useState('');
const [isHazmat,setIsHazmat] = useState(false)

const [message, setMessage] = useState('');
const [userId2,setuserId2] = useState('')
const [checkDialog,setCheckDialog] = useState('none')
const [hashpassword,setHashPassword] =useState('')

const { user } = useSelector(state => state.user);
const hasPermission = user?.role==="Admin" 

  const generatePassword = () => {
    const length = 12; // specify the length of the password
    // const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]|:;"<>,.?/~'; // specify the characters to include in the password
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // specify the characters to include in the password
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    setPassword(password);
    setHashPassword(md5(password))
  };

  

  const [lbclient,setlbclient] = useState('none')
    const handleSafety = ()=>{
        if(isSafety){
            // setIsSafety(false)
            // setbtnenable('none')
            // if(btndisable=="inline-block"){
                
            //     setbtndisable('none')
            // }
            setdialog('inline-block')
           
        }
        else{
            setIsSafety(true)
            setbtnenable('inline-block')
            setlbclient('inline-block')
            
        }
      
    }
    const handleDisableDialogYes = ()=>{
        setIsSafety(false)
        setbtndisable("none")
        setbtnenable("none")
        setUserId('')
        setPassword('')
        setnotificationemail('')
        setdialog('none')
        setlbclient('none')

    }
    const handleDisableDialogNo = ()=>{
        setdialog('none')
    }
    const handleDisableClick =()=>{
        
            setdialog('inline-block') 
            
    }
    const handleEnableClick = ()=>{
        if(notificationemail===''){
           setenabledialog('inline-block')
        }
        else{

      
            
                fetch(Apiaddres.address+"/checkuserid.php?userid="+notificationemail)
                .then((res)=>res.json())
                .then((jsres)=>{
                    console.log(jsres)
                    if(jsres=='okay'){
                      setCheckUserId('okay')
                      setUserId(notificationemail)
                      generatePassword()
                      setbtndisable('inline-block')
                      setbtnenable('none')
                       }
                     
                       else{
                        setIsLoading(false);
                        setCheckDialog("inline-block")
                       }
                })


            

            
        }
    }
    const { response: customer } = useFetch(process.env.REACT_APP_BASE_API_URL+'/customer-details/'+id);
    useEffect(() => {
        if (customer?.data)
        {
            setLegalName(customer.data.legal_name)
            setTradeName(customer.data.trade_name)
            setUserId(customer.data.user_id)
            setuserId2(customer.data.user_id)
            setHashPassword(customer.data.password)
            setPassword(customer.data.password)
            setStreetAddress(customer.data.street_no)
            setStreetName(customer.data.street_name)
            setSuiteApt(customer.data.suite_address)
            setCity(customer.data.city)
            setProvince(customer.data.province)
            setPostalCode(customer.data.postal_code)
            setTelephone(customer.data.telephone)
            setCell(customer.data.cell)
            setFax(customer.data.fax)
            setEmail(customer.data.email)
            setFirstName(customer.data.cp_first_name)
            setLastName(customer.data.cp_last_name)
            setMiddleName(customer.data.cp_middle_name)
            setCvor(customer.data.cvor)
            setCvorDate(customer.data.cvor_date)
            setCvorIssueDate(customer.data.cvor_issue_date)
            setCvorExpiryDate(customer.data.cvor_expiry_date)
            setUsDot(customer.data.us_dot)
            setUsDotPin(customer.data.us_dot_pin)
            setUsDotDate(customer.data.us_dot_date)
            setIftaAcc(customer.data.ifta_acc)
            setOrFile(customer.data.or_file)
            setNy(customer.data.ny)
            setKyu(customer.data.kyu)
            setCt(customer.data.ct)
            setUcr(customer.data.ucr)
            setNmwdt(customer.data.nmwdt)
            setQcnir(customer.data.qcnir)
            setFedId(customer.data.fedid)
            setGst(customer.data.gst)
            setMcNo(customer.data.mc_no)
            setIrpAcc1(customer.data.irp_acc_1)
            setIrpAcc2(customer.data.irp_acc_2)
            setIrpAcc3(customer.data.irp_acc_3)
            setExpireDate1(customer.data.expire_date_1)
            setExpireDate2(customer.data.expire_date_2)
            setExpireDate3(customer.data.expire_date_3)
            setWeightGroup1A(customer.data.weight_group_1a)
            setWeightGroup1B(customer.data.weight_group_1b)
            setWeightGroup1C(customer.data.weight_group_1c)
            setWeightGroup2A(customer.data.weight_group_2a)
            setWeightGroup2B(customer.data.weight_group_2b)
            setWeightGroup2C(customer.data.weight_group_2c)
            setWeightGroup3A(customer.data.weight_group_3a)
            setWeightGroup3B(customer.data.weight_group_3b)
            setWeightGroup3C(customer.data.weight_group_3c)
            setScac1(customer.data.scac_1)
            setScac2(customer.data.scac_2)
            setScac3(customer.data.scac_3)
            setCarrierCode1(customer.data.carrier_code_1)
            setCarrierCode2(customer.data.carrier_code_2)
            setCarrierCode3(customer.data.carrier_code_3)
            setCarrierCodeDate(customer.data.carrier_code_date)
            setScacDate(customer.data.scac_date)
            setScacPassword(customer.data.scac_password)
            setIsIfta(customer.data.ifta)
            setIsSafetye2(customer.data.is_safety)
            setIsActivated(customer.data.is_working)
            setQuarter(customer.data.quarter)
            setnotificationemail(customer.data.user_id)
            setIsHazmat(customer.data.is_hazmat)
            if(isSafety2){
                setbtndisable('inline-block')
                setIsSafety(isSafety2)
                setlbclient('inline-block')
            }

        }
    }, [customer,isSafety2])
    const [checkUserId,setCheckUserId] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(isSafety &&hashpassword==''){
            setError("Must Enter the new password. ");
            return;
        }
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);
        if(isSafety){
            if(btnenable=='inline-block'){
                setsavedialogalert('inline-block')
                setMessage("Client notification email must entered ")
                setIsLoading(false);
                return;
            }
        }
/////////////////////////////////////////////////////////////////////////
if(isSafety && btnenable=='inline-block'){
     // Define regular expressions for each criteria
 const lengthRegex = /.{8,}/;
 const uppercaseRegex = /[A-Z]/;
 const lowercaseRegex = /[a-z]/;
 const digitRegex = /\d/;
 //const specialCharRegex = /[^A-Za-z0-9]/;   && hasSpecialChar

 // Check each criteria
 const isLengthValid = lengthRegex.test(password); 
 const hasUppercase = uppercaseRegex.test(password);
 const hasLowercase = lowercaseRegex.test(password);
 const hasDigit = digitRegex.test(password);
 //const hasSpecialChar = specialCharRegex.test(password);

 // Check if all criteria are met
 const isStrongPassword = isLengthValid && hasUppercase && hasLowercase && hasDigit;
 if(!isStrongPassword){
     setMessage("Password must be at least 8 characters long and includes uppercase, lowercase and digits.")
     setsavedialogalert('inline-block')
     return
 }        ///////////////////////////////////////////////////////
}
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("legal_name", legalName)
        formData.append("trade_name", tradeName)
        formData.append("user_id", userId)
        formData.append("password", hashpassword)
        formData.append("street_no", streetAddress)
        formData.append("street_name", streetName)
        formData.append("suite_address", suiteApt)
        formData.append("city", city)
        formData.append("province", province)
        formData.append("postal_code", postalCode)
        formData.append("telephone", telephone)
        formData.append("cell", cell)
        formData.append("fax", fax)
        formData.append("email", email)
        formData.append("cp_first_name", firstName)
        formData.append("cp_last_name", lastName)
        formData.append("cp_middle_name", middleName)
        formData.append("cvor", cvor)
        formData.append("cvor_date", cvorDate)
        formData.append("cvor_issue_date", cvorIssueDate)
        formData.append("cvor_expiry_date", cvorExpiryDate)
        formData.append("us_dot", usDot)
        formData.append("us_dot_pin", usDotPin)
        formData.append("us_dot_date", usDotDate)
        formData.append("ifta_acc", iftaAcc)
        formData.append("or_file", orFile)
        formData.append("ny", ny)
        formData.append("kyu", kyu)
        formData.append("ct", ct)
        formData.append("ucr", ucr)
        formData.append("nmwdt", nmwdt)
        formData.append("qcnir", qcnir)
        formData.append("fedid", fedId)
        formData.append("gst", gst)
        formData.append("mc_no", mcNo)
        formData.append("irp_acc_1", irpAcc1)
        formData.append("irp_acc_2", irpAcc2)
        formData.append("irp_acc_3", irpAcc3)
        formData.append("expire_date_1", expireDate1)
        formData.append("expire_date_2", expireDate2)
        formData.append("expire_date_3", expireDate3)
        formData.append("scac_1", scac1)
        formData.append("scac_2", scac2)
        formData.append("scac_3", scac3)
        formData.append("carrier_code_1", carrierCode1)
        formData.append("carrier_code_2", carrierCode2)
        formData.append("carrier_code_3", carrierCode3)
        formData.append("carrier_code_date", carrierCodeDate)
        formData.append("scac_date", scacDate)
        formData.append("scac_password", scacPassword)
        formData.append("ifta", isIfta)
        formData.append("is_safety", isSafety)
        formData.append("is_working", isActivated)
        formData.append("quarter", quarter)
        formData.append("is_hazmat",isHazmat)
        var check =0;
    
        if(isSafety && btndisable=='inline-block'){

             if (checkUserId=="okay" || userId==userId2){
                fetch(process.env.REACT_APP_BASE_API_URL+'/customers/'+id, {
                    method: 'POST',
                    headers: { 
                        "Accept": "application/json",
                        "Authorization": "Bearer "+localStorage.getItem("token")
                    },
                    body: formData
                })
                .then(response => {
                    if (!response.ok) {
                        if (response.status == 401)
                        {
                            store.dispatch(removeUser());
                            throw Error('Unauthorized access. Please retry after login');
                        }
                        else
                            throw Error('There was some error while editing customer, please try again.');
                    }
            
                    return response.json();
                })
                .then(data => {
                    //cardRef.current.scrollIntoView();
                    setIsLoading(false);
            
                    if (data?.status == 'success')
                    {
                        setSuccess(data.message);
                        if(checkUserId=='okay'){
                            try {
                                setSuccess(data.message+" and know sending the email ");
                                // fetch(process.env.REACT_APP_BASE_API_URL+'/send-email', {
                                    var message = "Dear "+legalName+",<br><br>Welcome to Smart Trucking Consultants. We are delighted to have you on board and excitedto grant you access to your online portal.<br><br><br>Your portal is designed to make your experience with us seamless and efficient.<br><br> <b>Password: "+password+" </b>  <br><br>Access the portal here: <a href='https://clientportal.smartrucking.ca/'>Click Here</a><br><br>If you have any questions or require assistance, our support team is always ready to help.Contact us at info@smartruckingservices.com or [905-581-6105].";
                                    var subject="Welcome to Your Online Portal Access!";
                                fetch(Apiaddres.mailapi+'inde.php', {
                                 method: 'POST',
                                 body:JSON.stringify(
                                   {username:tradeName,
                                 email:userId,
                                 password:password,
                                 message:message,
                                 subject:subject
                                 })
                                }).then((res)=>res.json())
                                .then((jsres)=>{
                                console.log(JSON.stringify(jsres))
                                setsavedialogalert('inline-block')
                                setMessage("Successfully updated and the email have been sent to the user")
                                setSuccess('')
                                });
                                
                                } catch (error) {
                                console.error('Error sending email:', error);
                                // Handle network error
                                }  
                        }

            
                    }
                    else if (data?.status == 'validation_error')
                    {
                        setValidationErrors(data.messages);
                    }
                    else if (data?.status == 'error' && data?.message)
                    {
                        setError(data.message);
                    }
                    else
                    {
                        setError('There was some error while editing customer, please try again.');
                    }
                })
                .catch(error => {
                    //cardRef.current.scrollIntoView();
                    setIsLoading(false);
                    setError(error.message);
                })
            }
            else{
                setCheckDialog("inline-block")
            }

        }
        else{
            fetch(process.env.REACT_APP_BASE_API_URL+'/customers/'+id, {
                method: 'POST',
                headers: { 
                    "Accept": "application/json",
                    "Authorization": "Bearer "+localStorage.getItem("token")
                },
                body: formData
            })
            .then(response => {
                if (!response.ok) {
                    if (response.status == 401)
                    {
                        store.dispatch(removeUser());
                        throw Error('Unauthorized access. Please retry after login');
                    }
                    else
                        throw Error('There was some error while editing customer, please try again.');
                }
        
                return response.json();
            })
            .then(data => {
                //cardRef.current.scrollIntoView();
                setIsLoading(false);
        
                if (data?.status == 'success')
                {
                    setSuccess(data.message);
        
                }
                else if (data?.status == 'validation_error')
                {
                    setValidationErrors(data.messages);
                }
                else if (data?.status == 'error' && data?.message)
                {
                    setError(data.message);
                }
                else
                {
                    setError('There was some error while editing customer, please try again.');
                }
            })
            .catch(error => {
                //cardRef.current.scrollIntoView();
                setIsLoading(false);
                setError(error.message);
            })
        }

// saving the data
if(!isActivated){
    console.log("inside the fetch fun");
    fetch(process.env.REACT_APP_BASE_API_URL+'/customer-archive/'+id,{ 
        method:'POST',
        headers: { 
            "Accept": "application/json",
            "Authorization": "Bearer "+localStorage.getItem("token"),
        },
      
    }).
    then((res)=>res.json()).then((jsres)=>console.log("okay"))
}
              
// window.location.reload();    
}

    return (
        <div className="container-fluid">
            
            <form onSubmit={handleSubmit}>
                <Card ref={cardRef} className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Edit Customer</h5>
                        <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                    </Card.Header>
                    <Card.Body>
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <fieldset className='mb-3'>
                            <legend>Personal Info</legend>
                            <div className="form-row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Legal Name *"
                                        value={legalName}
                                        setter={setLegalName}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Trade Name"
                                        value={tradeName}
                                        setter={setTradeName}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="User Id"
                                        value={userId}
                                        setter={setUserId}
                                        disabled={true}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                <Form.Label className="mb-1">Password</Form.Label>
                                    <Form.Control 
                                        type='password'
                                        
                                        disabled={true}
                                       
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Street # *"
                                        value={streetAddress}
                                        setter={setStreetAddress}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Street Name *"
                                        value={streetName}
                                        setter={setStreetName}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Suite/Apt #"
                                        value={suiteApt}
                                        setter={setSuiteApt}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="City *"
                                        value={city}
                                        setter={setCity}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Province Code *"
                                        value={province}
                                        setter={setProvince}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Postal Code *"
                                        value={postalCode}
                                        setter={setPostalCode}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Telephone #"
                                        value={telephone}
                                        setter={setTelephone}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Fax #"
                                        value={fax}
                                        setter={setFax}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Cell #"
                                        value={cell}
                                        setter={setCell}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Email"
                                        type='email'
                                        value={email}
                                        setter={setEmail}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                              
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="First Name *"
                                        value={firstName}
                                        setter={setFirstName}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Middle Name"
                                        value={middleName}
                                        setter={setMiddleName}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Last Name *"
                                        value={lastName}
                                        setter={setLastName}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Notification Email"
                                        value={notificationemail}
                                        setter={setnotificationemail}
                                        disabled={!hasPermission}
                                        />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='mb-3'>
                            <legend>Other Info</legend>
                            <div className="form-row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="CVOR #"
                                        value={cvor}
                                        setter={setCvor}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="CVOR Date"
                                        type={'date'}
                                        value={cvorDate}
                                        setter={setCvorDate}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="CVOR Issue Date"
                                        type={'date'}
                                        value={cvorIssueDate}
                                        setter={setCvorIssueDate}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="CVOR Expiry Date"
                                        type={'date'}
                                        value={cvorExpiryDate}
                                        setter={setCvorExpiryDate}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="US DOT # *"
                                        value={usDot}
                                        setter={setUsDot}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="US DOT Pin"
                                        value={usDotPin}
                                        setter={setUsDotPin}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="US DOT Date"
                                        type={'date'}
                                        value={usDotDate}
                                        setter={setUsDotDate}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="OR File # *"
                                        value={orFile}
                                        setter={setOrFile}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="NY # *"
                                        value={ny}
                                        setter={setNy}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="KYU # *"
                                        value={kyu}
                                        setter={setKyu}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="NM WDT # *"
                                        value={nmwdt}
                                        setter={setNmwdt}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="CT # *"
                                        value={ct}
                                        setter={setCt}
                                        required={true}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Carrier Code Date"
                                        type={'date'}
                                        value={carrierCodeDate}
                                        setter={setCarrierCodeDate}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Carrier Code 1 (PARS)"
                                        value={carrierCode1}
                                        setter={setCarrierCode1}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="FMCSA Login"
                                        value={carrierCode2}
                                        setter={setCarrierCode2}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="CARB #"
                                        value={carrierCode3}
                                        setter={setCarrierCode3}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label={`UCR${usDot != '' ? ' *' : ''}`}
                                        value={ucr}
                                        setter={setUcr}
                                        required={usDot != ''}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="SCAC Date"
                                        type={'date'}
                                        value={scacDate}
                                        setter={setScacDate}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="SCAC 1 (PAPS)"
                                        value={scac1}
                                        setter={setScac1}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="SCAC 2 (PAPS)"
                                        value={scac2}
                                        setter={setScac2}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="SCAC 3 (PAPS)"
                                        value={scac3}
                                        setter={setScac3}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="SCAC Password"
                                        value={scacPassword}
                                        setter={setScacPassword}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Fleet1 #"
                                        value={irpAcc1}
                                        setter={setIrpAcc1}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Expiry Date 1"
                                        type='date'
                                        value={expireDate1}
                                        setter={setExpireDate1}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Fleet2 #"
                                        value={irpAcc2}
                                        setter={setIrpAcc2}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Expiry Date 2"
                                        type='date'
                                        value={expireDate2}
                                        setter={setExpireDate2}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Fleet3 #"
                                        value={irpAcc3}
                                        setter={setIrpAcc3}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="Expiry Date 3"
                                        type='date'
                                        value={expireDate3}
                                        setter={setExpireDate3}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="QC NIR #"
                                        value={qcnir}
                                        setter={setQcnir}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="FED ID #"
                                        value={fedId}
                                        setter={setFedId}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="GST #"
                                        value={gst}
                                        setter={setGst}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="MC No"
                                        value={mcNo}
                                        setter={setMcNo}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Input
                                        label="IFTA A/c #"
                                        value={iftaAcc}
                                        setter={setIftaAcc}
                                        disabled={!hasPermission}
                                        />
                                </div>
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Form.Label className="mb-1">IFTA - Yes/No</Form.Label>
                                    <div key={`inline-radio`}>
                                        <Form.Check
                                            inline
                                            checked={isIfta === true}
                                            onChange={() => setIsIfta(true)}
                                            label="Yes"
                                            type='radio'
                                            name='ifta-yes-no'
                                            id='ifta-yes'
                                            
                                        />
                                        <Form.Check
                                            inline
                                            checked={isIfta === false}
                                            onChange={() => setIsIfta(false)}
                                            label="No"
                                            type='radio'
                                            name='ifta-yes-no'
                                            id='ifta-no'
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-6 mb-3">
                                    <Form.Label className="mb-1">Safety Account</Form.Label>
                                    <div key={`inline-checkbox`}>
                                        <Form.Check
                                            inline
                                            checked={isSafety}
                                            onChange={handleSafety}
                                            label="Yes"
                                            type='checkbox'
                                            id='safety-checkbox'
                                        />
                                    </div> 
                                        
                                </div>
                                
                                <div className='col-xl-2 col-md-6 mb-3'>
                                    <span style={{display:lbclient}}>   
                                        <Form.Label className="mb-1 ">Client Login</Form.Label>
                                            <div>
                                            <button 
                                                type='button'   
                                                style={{display:btnenable}}                                     
                                                onClick={handleEnableClick}
                                                className="btn btn-primary  w-100px"
                                                >Enable Notification</button>

                                                <button 
                                                type='button'
                                                style={{display:btndisable}}
                                                className="btn btn-primary btn-danger w-100px"
                                                onClick={handleDisableClick}
                                                >Disable Notification</button>
                                            
                                            </div>
                                        </span>
                                </div>
                                    
                                
                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Form.Label className="mb-1">Is Activated</Form.Label>
                                    <div key={`inline-checkbox`}>
                                        <Form.Check
                                            inline
                                            checked={isActivated === true}
                                            onChange={() => setIsActivated(!isActivated)}
                                            label="Yes"
                                            type='checkbox'
                                            id='active-checkbox'
                                        />
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <Form.Label className="mb-1">Hazmat Safety Customer</Form.Label>
                                    <div key={`inline-checkbox`}>
                                        <Form.Check
                                            inline
                                            checked={isHazmat === true}
                                            onChange={() => setIsHazmat(!isHazmat)}
                                            label="Yes"
                                            type='checkbox'
                                            id='active-checkbox'
                                        />
                                    </div>
                                </div>

                                { isIfta === true ? (
                                    <div className="col-xl-4 col-md-6 mb-3">
                                        <Select 
                                            label="Quarter"
                                            value={quarter}
                                            setter={setQuarter}
                                            required={isIfta === true}
                                            options={[
                                                { text: 'Select a Quarter', value: '' },
                                                { text: '2010 - Quarter 4 (Starting from 2010-10-01)', value: '2010-10-01' },
                                                { text: '2010 - Quarter 3 (Starting from 2010-07-01)', value: '2010-07-01' },
                                                { text: '2010 - Quarter 2 (Starting from 2010-04-01)', value: '2010-04-01' },
                                                { text: '2010 - Quarter 1 (Starting from 2010-01-01)', value: '2010-01-01' },
                                                { text: '2009 - Quarter 4 (Starting from 2009-10-01)', value: '2009-10-01' },
                                                { text: '2009 - Quarter 3 (Starting from 2009-07-01)', value: '2009-07-01' },
                                            ]}
                                            />
                                    </div>
                                ) : null }
                            </div>
                        </fieldset>
                    </Card.Body>
                    <Card.Footer className='text-right'>
                        <button 
                            type="submit" 
                            className="btn btn-primary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </Card.Footer>
                </Card>
            </form>
            {/* Message Box  Disable */}
            <div style={{display:showdialog}} >
            <div>
      <div className="card shadow mb-4" style={{
            position: 'fixed',
            height: 'calc(100vh + 10px)',
            width: 'calc(100vw + 10px)',
            top: '-10px',
            left: '-10px',
            background: '#000000dd',
            display: 'flex',
            zIndex: 100,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <img src='./confirm.svg' style={{width: '70px'}} />

            <h4 className="h4 mb-1 mt-2 text-white">
                Note
            </h4>
            <div > <p className='text-white' >Are you sure u want to disabled the safety account</p> </div>
                
                <div>
                    <button onClick={handleDisableDialogYes}  className="btn btn-success btn-submit w-100px mx-2">Yes</button>
                    <button onClick={handleDisableDialogNo} className="btn btn-success btn-submit w-100px mx-2">No</button>
                </div>
            
        </div>
    </div>
            </div>
            {/** Message Box Enable */}

            <div style={{display:enabledialog}} >
            <div>
      <div className="card shadow mb-4" style={{
            position: 'fixed',
            height: 'calc(100vh + 10px)',
            width: 'calc(100vw + 10px)',
            top: '-10px',
            left: '-10px',
            background: '#000000dd',
            display: 'flex',
            zIndex: 100,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <img src='/confirm.svg' style={{width: '70px'}} />

            <h4 className="h4 mb-1 mt-2 text-white">
                Note
            </h4>
            <div > <p className='text-white' >Please enter notification email </p> </div>
                
                <div>
                    <button onClick={()=>setenabledialog('none')}  className="btn btn-success btn-submit w-100px mx-2">Okay</button>
                </div>
            
        </div>
    </div>
            </div>
                       {/** Message Box Check User */}

                       <div style={{display:checkDialog}} >
            <div>
      <div className="card shadow mb-4" style={{
            position: 'fixed',
            height: 'calc(100vh + 10px)',
            width: 'calc(100vw + 10px)',
            top: '-10px',
            left: '-10px',
            background: '#000000dd',
            display: 'flex',
            zIndex: 100,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <img src='./confirm.svg' style={{width: '70px'}} />

            <h4 className="h4 mb-1 mt-2 text-white">
                Note
            </h4>
            <div > <p className='text-white' > Sorry this email is already in use.</p> </div>
                
                <div>
                    <button onClick={()=>setCheckDialog('none')}  className="btn btn-success btn-submit w-100px mx-2">Okay</button>
                </div>
            
        </div>
    </div>
            </div>
{/************************* Message Box Save Check *****************************/}

            <div style={{display:savedialogalter}} >
            <div>
      <div className="card shadow mb-4" style={{
            position: 'fixed',
            height: 'calc(100vh + 10px)',
            width: 'calc(100vw + 10px)',
            top: '-10px',
            left: '-10px',
            background: '#000000dd',
            display: 'flex',
            zIndex: 100,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <img src='/confirm.svg' style={{width: '70px'}} />

            <h4 className="h4 mb-1 mt-2 text-white">
                Note
            </h4>
            <div > <p className='text-white' > {message}  </p> </div>
                
                <div>
                    <button onClick={()=>setsavedialogalert('none')}  className="btn btn-success btn-submit w-100px mx-2">Okay</button>
                </div>
            
        </div>
    </div>
            </div>

<Footer/>
        </div>
    );
}
 
export default Edit;