import { useEffect, useState } from 'react';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from "dayjs";
 
 

const Index = () => {
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        if (user.role !== 'Admin') {
            navigate('/unauthorized');
        }
    }, [user]);

    document.title = "Login Logs | Smart Trucking Services";
    
    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/login-logs');
    
    const [apiUrl, setApiUrl] = useState(url);

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.logs?.data ?? []);
        setMeta(response?.logs?.meta ?? []);
    }, [response])

    return (
        <div className="container-fluid">
            <Card className="shadow mb-4">
            <Card.Header className="d-flex align-items-center">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Login Logs</h5>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <Table responsive bordered hover striped>
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Username</th>
                                            <th>IP Address</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item, index) => (
                                            <tr key={ item.id } style={{
                                                backgroundColor: item.is_error ? '#ffbfbf' : ''
                                            }}>
                                                {/* <td>{dayjs(item.login_time?.date).format("ddd, MMM D, YYYY h:mm A") }</td> */}
                                                <td>{item.login_time}</td>
                                                <td>{ item.username }</td>
                                                <td>{ item.user_ip }</td>
                                                <td>{ item.status }</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default Index;